@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: "Saira";
  src: local("Saira Thin"), url("./assets/fonts/Saira.woff") format("woff");
}

@font-face {
  font-family: 'Neutro';
  src: local('Neutro Extra Bold'), url("./assets/fonts/Neutro.woff") format('woff');
} */

@font-face {
  font-family: 'Marker Regular';
  src: local('Marker Regular'), url("./assets/fonts/Marker-Regular.woff") format('woff');
}

@font-face {
  font-family: 'Graffiti City';
  src: local('Graffiti City'), url("./assets/fonts/Graffiti-City.woff") format('woff');
}

@font-face {
  font-family: 'XXII Ultimate-Black-Metal';
  src: local('XXII Ultimate-Black-Metal'), url('./assets/fonts/XXII-Ultimate-Black-Metal.woff') format('woff');
}

@font-face {
  font-family: 'OldStyle 1 HPLHS';
  src: local('OldStyle 1 HPLHS'), url('./assets/fonts/OldStyle1.woff') format('woff');
}

@font-face {
  font-family: 'Aa_scum';
  src: local('Aa_scum'), url('./assets/fonts/Aa_scum-Regular.otf')
}

.font-print {
  font-family: 'OldStyle 1 HPLHS';
}

.font-scum {
  font-family: 'Aa_scum';
}

.font-metal {
  font-family: 'XXII Ultimate-Black-Metal'
}

.slide-fade-up {
  animation: slideFadeUp .225s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

a:nth-of-type(2) .slide-fade-up {
  animation-delay: 0.125s;
}

a:nth-of-type(3) .slide-fade-up {
  animation-delay: 0.25s;
}

@keyframes slideFadeUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
}

.wp-blog-content p {
  padding: 1rem 0;
}

.about-content p {
  /* padding: 2rem 0; */
}

@media only screen and (max-width: 768px) {
  .about-content p {
    /* border: 1px solid #194307; */
    margin: 0rem 0;
    background: #ffffee;
    padding: 1rem;
  }
}