
h2 {
  /* color: white; */
  text-shadow:
   -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff !important;
}

.text-outlined {
  font-weight: 900;
  text-shadow:    -1px -1px 0 #194307,
      1px -1px 0 #194307,
      -1px 1px 0 #194307,
       1px 1px 0 #194307 !important;
}

.blur-panel {
  backdrop-filter: blur(8px) contrast(1.2);
}

.blur-panel-max {
  backdrop-filter: blur(28px) contrast(1.2) brightness(5);
}

.blur-panel-light {
  backdrop-filter: blur(2px) contrast(1.2);
}

.dithered-background {
  background-image: url('./assets/images/dither-bg.png');
  background-size: 4px 4px;
  mix-blend-mode: darken;
  opacity: 0.5;
  background-position: 0px 0px;
  backdrop-filter: grayscale(1);
  animation: animatedBackground 50s linear infinite;
  background-color: #ffffee;

}


.dithered-background-no-animation {
  background-image: url('./assets/images/dither-bg.png');
  background-size: 4px 4px;
  mix-blend-mode: darken;
  opacity: 0.5;
  background-position: 0px 0px;
  /* animation: animatedBackground 50s linear infinite; */
  background-color: #ffffee;

}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}

.about hr {
  margin: 2rem 0;
}

.strange-green-filter {
  filter: invert(19%) sepia(32%) saturate(1393%) hue-rotate(60deg) brightness(97%) contrast(96%);
}

.on-hover-strange-green-filter {
  transition: 2s;
}

.on-hover-strange-green-filter:hover {
 
  filter: invert(19%) sepia(32%) saturate(1393%) hue-rotate(60deg) brightness(97%) contrast(96%); 
}


#nc-main {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    -webkit-box-shadow: 0 0 30px #241d20;
    box-shadow: 0 0 30px #241d20;
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
}

.full-wh {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.bg-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.bg-animation{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes zoomout {
  from {
    transform: scale(2);
    opacity: 1;
    
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.carousel-container:hover .swipe-icon {
  margin-top:0px;
  opacity: 1;
  transition: 0.25s;
  transition-timing-function: ease-in-out;
  animation: fadeOut 0.5s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 1;
}

.swipe-icon {
  opacity: 0;
  margin-top: 32px;
  transition: 0.25s;
transition-timing-function: ease-in-out;
}


.fade-out {
  animation: fadeOut 1s ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  transition: 0.2s;
}

/* Track */
.releases-list::-webkit-scrollbar-track {
  background: #f1f1f1;

}

/* Handle */
.releases-list::-webkit-scrollbar-thumb {
  background: #19430733;
}


.releases-list::-webkit-scrollbar:hover {
  width: 10px;
}

.home-blog-single-content p {
  padding: 1rem 1rem;
}

.home-about-paragraph p {
  margin-bottom: 1rem;
}

/* @media only screen and (max-width: 1600px) {
  .home-about-paragraph p:nth-of-type(n+3) {
    display: none;
  }
} */